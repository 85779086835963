import styled from '@emotion/styled'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import React from 'react'
import ga from 'src/utils/pixels/gtag'
import img from '../../../public/images/garage-empty.webp'
import BaseButton from '../../components/Buttons/BaseButton'
import FlexBox from '../../components/FlexBox'
import { Colors } from '../../constants/theme'
import { RedirectUrls } from '../../types/types'

const StyledCTAItemsContainer = styled.div`
  display: grid;
  text-align: start;
`

const StyledCTAItem = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
`

const StyledCTAItemDescription = styled.div`
  font-size: 15px;
  line-height: 16px;
  color: ${Colors.black400};
`

const CTASection = () => {
  const { t } = useTranslation('landing')
  const handleOnClick = () => {
    ga.event('anfitrion_click', { location: 'CTASection' })
  }

  const ITEMS = t('INDEX_CTA.FT', { returnObjects: true })

  const title = (
    <div className="flex flex-col items-center md:items-start">
      <h3 className="my-[10px] w-full max-w-[393px] text-center text-[32px] font-semibold leading-8 text-black-400 md:max-w-full md:text-left">
        {t('INDEX_CTA.HEADING')}
      </h3>
      <p className="w-full max-w-[556px] text-center font-semibold uppercase text-blue-400 md:max-w-full md:text-left md:text-lg">
        {t('INDEX_CTA.SUBHEADING')}
      </p>
    </div>
  )

  const content = (
    <StyledCTAItemsContainer>
      {ITEMS.map(({ src, alt, text }) => (
        <StyledCTAItem key={src}>
          <FlexBox mr={2}>
            <Image src={src} alt={alt} width={25} height={25} quality={100} />
          </FlexBox>
          <FlexBox column flex={6}>
            <StyledCTAItemDescription>{text}</StyledCTAItemDescription>
          </FlexBox>
        </StyledCTAItem>
      ))}
    </StyledCTAItemsContainer>
  )

  return (
    <section className="flex w-full justify-center bg-grey-300 pt-24 md:bg-white-500">
      <div className="relative flex w-full flex-col items-center justify-center md:h-[460px] md:flex-row">
        <div className="absolute z-10 hidden size-full max-w-[1832px] items-center justify-center md:flex">
          <div className="flex size-full max-w-96 flex-col justify-center px-5 md:pl-7 md:pr-0 lg:pl-12 xxl:pl-0">
            {title}
            {content}
          </div>
          <div className="flex size-full items-end justify-end px-5 pb-24 md:pl-0 md:pr-7 lg:pr-12 xxl:pr-0">
            <BaseButton
              fontSize={14}
              width={245}
              size="medium"
              color="primary"
              variant="contained"
              rounded
              href={RedirectUrls.Anfitrion}
              onClick={handleOnClick}
            >
              {t('INDEX_CTA.BUTTON')}
            </BaseButton>
          </div>
        </div>
        <div className="flex size-full items-center justify-center bg-grey-300 px-5 md:w-1/2">
          <div className="flex flex-col items-center justify-center gap-11 pb-24 md:hidden">
            {title}
            {content}
            <div className="flex w-full flex-col flex-wrap items-center justify-center px-5 sm:flex-row">
              <BaseButton
                fontSize={14}
                width={245}
                size="medium"
                color="primary"
                variant="contained"
                rounded
                href={RedirectUrls.Anfitrion}
                onClick={handleOnClick}
              >
                {t('INDEX_CTA.BUTTON')}
              </BaseButton>
            </div>
          </div>
        </div>
        <div className="relative hidden h-[272px] w-full items-center justify-center overflow-hidden md:flex md:h-full md:w-1/2">
          <Image
            src={img}
            fill
            style={{ objectFit: 'cover' }}
            alt="worker"
            sizes="100vw"
            loading="lazy"
            quality={100}
          />
        </div>
        <div className="relative flex min-h-[309px] w-full items-center justify-center overflow-hidden sm:min-h-[272px] md:hidden md:h-full md:w-1/2">
          <Image
            src={img}
            alt="worker"
            sizes="100vw"
            loading="lazy"
            quality={100}
            className={'absolute h-full w-auto sm:h-auto sm:w-full'}
          />
        </div>
      </div>
    </section>
  )
}

export default React.memo(CTASection)
